import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import {
  MQ,
  interactionFeedback,
  typographyToCss,
} from "@/react-app/styles/helpers";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import { DESKTOP_HEIGHT_WITH_HEADER_LINKS } from "@/react-components/Header/constants";

const { spaces, colors } = xxlTheme;

const typography = {
  heading: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 34,
    },
  },
  description: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  descriptionLink: {
    fontFamily: "var(--font-family-medium)",
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
};

const { heading, description, descriptionLink } = typography;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

type WrapperProps = {
  hasVideo?: boolean;
};

export const CarouselWrapper = styled.div<WrapperProps>(
  ({ hasVideo = false }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${MQ("tablet")} {
      flex-direction: ${hasVideo ? "column" : "row"};
    }

    ${MQ("tabletHorizontal")} {
      flex-direction: row;
    }
  `
);

type SliderWrapperProps = {
  componentWidth: string;
  hasImage: boolean;
  hasVideo: boolean;
  imagePosition?: string;
};

export const SliderWrapper = styled.div<SliderWrapperProps>(
  ({
    componentWidth = "100%",
    hasImage,
    hasVideo,
    imagePosition = "left",
  }) => css`
    width: ${componentWidth};

    ${hasImage &&
    !hasVideo &&
    css`
      & .dots {
        ${MQ("tablet")} {
          width: calc(100dvw - 2 * ${spaces.smallRegular});
          position: relative;
          left: ${imagePosition === "left" ? "-280px" : "auto"};
          right: ${imagePosition === "right" ? "0" : "auto"};
        }
      }

      & .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
        ${MQ("tablet")} {
          min-height: 470px;
        }

        ${MQ("tabletHorizontal")} {
          min-height: 100%;
        }
      }
    `}
  `
);

type ImageProps = {
  hasDescription?: boolean;
};

const imageSizes = {
  mobile: {
    onlyImage: {
      h: "284px",
    },
    hasDescription: {
      h: "150px",
    },
  },
  tablet: {
    onlyImage: {
      h: "470px",
    },
    hasDescription: {
      h: "240px",
    },
  },
  desktop: {
    onlyImage: {
      h: "470px",
    },
    hasDescription: {
      h: "328px",
    },
  },
} as const;

export const ImageWrapper = styled.figure<ImageProps>(
  ({ hasDescription = false }) => css`
    width: 100%;
    height: ${imageSizes.mobile.onlyImage.h};

    ${MQ("tablet")} {
      height: ${imageSizes.tablet.onlyImage.h};
    }

    ${MQ("tabletHorizontal")} {
      height: ${imageSizes.desktop.onlyImage.h};
    }

    ${hasDescription &&
    css`
      margin-bottom: ${spaces.smallRegular};
      height: ${imageSizes.mobile.hasDescription.h};

      ${MQ("smallTablet")} {
        height: ${imageSizes.tablet.hasDescription.h};
      }

      ${MQ("tabletHorizontal")} {
        height: ${imageSizes.desktop.hasDescription.h};
      }
    `}
  `
);

export const DisclaimerInformationWrapper = styled.div`
  position: absolute;
  left: ${spaces.smallRegular};
  top: ${spaces.smallRegular};
`;

type MediaWrapperProps = {
  componentWidth: string;
  paddingLeft?: boolean;
  paddingRight?: boolean;
  hasVideo?: boolean;
};

export const MediaWrapper = styled.div<MediaWrapperProps>(
  ({
    componentWidth,
    paddingLeft = false,
    paddingRight = false,
    hasVideo = false,
  }) => css`
    width: ${componentWidth};
    height: 100%;
    padding-bottom: ${spaces.smallRegular};
    order: ${paddingLeft ? -1 : 0};
    position: relative;

    ${MQ("tablet")} {
      padding-bottom: ${hasVideo ? `${spaces.smallRegular}` : 0};
      order: ${hasVideo ? -1 : 0};
    }

    ${paddingLeft &&
    css`
      ${MQ("tablet")} {
        padding-left: ${spaces.smallRegular};
      }
      ${MQ("desktop")} {
        padding-left: ${spaces.large};
      }
    `}

    ${paddingRight &&
    css`
      ${MQ("tablet")} {
        padding-right: ${spaces.smallRegular};
      }
      ${MQ("desktop")} {
        padding-right: ${spaces.large};
      }
    `}

    ${hasVideo &&
    css`
      ${MQ("tabletHorizontal")} {
        padding-bottom: 0;
        order: 0;
      }
    `}
  `
);

export const Heading = styled.h2`
  scroll-margin: ${() =>
      `${
        useXxlMediaQuery("MobileMediaQuery")
          ? 0
          : DESKTOP_HEIGHT_WITH_HEADER_LINKS
      }px`}
    0 0;
  display: inline-flex;
  ${typographyToCss(heading)};
  gap: ${spaces.mini};
  margin: 0 0 ${spaces.smallRegular};

  ${MQ("desktop")} {
    margin: 0 0 ${spaces.large};
  }

  & svg {
    margin-right: ${spaces.mini};

    ${MQ("tablet")} {
      margin-right: ${spaces.smallRegular};
    }
  }

  &:empty {
    display: none;
  }
`;

export const HeadingTextWrapper = styled.span`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const Description = styled.p`
  margin: 0 0 ${spaces.smallRegular};
  ${typographyToCss(description)};
  color: ${colors.xxlWebBlack};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DescriptionLinkIndicator = styled.span`
  margin: 0;
  ${typographyToCss(descriptionLink)};
  color: ${colors.xxlWebBlack};
  display: flex;
  align-items: center;

  & svg {
    margin-left: ${spaces.mini};
  }
`;

export const DescriptionLinkWrapper = styled.a`
  &:any-link {
    ${interactionFeedback(css`
      cursor: pointer;
      & ${DescriptionLinkIndicator} {
        text-decoration: underline;
      }
    `)};
  }

  & > figure > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ForceFullWidthWrapper = styled.div`
  margin-left: calc(-50vw + 50%);
  width: 100vw;
`;
