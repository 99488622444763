import { SkeletonWrapper } from "react-app/src/components/Common";
import { Slider } from "react-app/src/components/Common/Slider";
import { trackProductImpression } from "react-app/src/components/PersonalizedProductList/tracking-helper";
import {
  DEFAULT_GRID_VALUE_DESKTOP,
  PERSONALIZED_PRODUCT_LIST_QUERY_NAME,
} from "react-app/src/components/Product/constants";
import {
  cardHeightMobile,
  nonHoverableCardHeight,
} from "react-app/src/components/Product/Product.styled";
import { useTracking } from "react-app/src/contexts/Tracking";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import type { ProductCardDataV2 } from "react-app/src/utils/ProductData/product-card-data-helper";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";
import { InView } from "../../common/InView/InView";
import { ProductCardV2 } from "../../ProductListV2/ProductCardV2";
import type { RecommendationStrategy } from "./Handler.helper";
import { InnerWrapper, Wrapper } from "./Handler.styled";

const SLIDER_MODE = "free-snap";

type HandlerProps = {
  carouselProducts?: (ProductCardDataV2 & { hasRewardsPrices: boolean })[];
  sliderConfig?: {
    slidesPerView: {
      laptop: number;
      mobile: number;
      tablet: number;
    };
    spacing: number;
  };
  strategy: RecommendationStrategy;
};

const _Handler = (props: HandlerProps) => {
  const {
    carouselProducts,
    sliderConfig = {
      slidesPerView: {
        laptop: 6,
        mobile: 2,
        tablet: 3,
      },
      spacing: 6,
    },
    strategy,
  } = props;
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const trackers = useTracking();
  const cardHeight = `${
    isLaptopSize ? nonHoverableCardHeight : cardHeightMobile
  }px`;

  const slidesConfig = {
    perView: isLaptopSize
      ? sliderConfig.slidesPerView.laptop
      : isMobile
        ? sliderConfig.slidesPerView.mobile
        : sliderConfig.slidesPerView.tablet,
    spacing: sliderConfig.spacing,
  };

  return (
    <Wrapper>
      <InnerWrapper hasPadding={false}>
        <div style={{ minHeight: cardHeight }}>
          <SkeletonWrapper isLoading={carouselProducts === undefined}>
            <div style={{ minHeight: cardHeight, width: "100%" }}>
              <Slider
                items={(carouselProducts ?? []).map(
                  ({ hasRewardsPrices, url, ...productData }, index) => {
                    const {
                      baseColor,
                      brandName,
                      code,
                      name,
                      price: { selling },
                    } = productData;
                    const separator = url.includes("?") ? "&" : "?";
                    const productListParameter = `${PERSONALIZED_PRODUCT_LIST_QUERY_NAME}=${strategy}`;

                    return (
                      <InView
                        key={productData.code}
                        onInView={() =>
                          trackProductImpression({
                            listName: strategy,
                            position: index + 1,
                            product: {
                              brandName,
                              code,
                              name,
                              style: baseColor,
                              salesPrice: selling.value,
                            },
                            trackers,
                          })
                        }
                      >
                        <ProductCardV2
                          productData={{
                            ...productData,
                            url: `${url}${separator}${productListParameter}`,
                          }}
                          isHoverable={false}
                          isSliderProductList={true}
                          isLaptopViewPort={isLaptopSize}
                          nrOfSelectedColumns={DEFAULT_GRID_VALUE_DESKTOP}
                          arrayIndex={index}
                          hasRewardPrices={hasRewardsPrices}
                        />
                      </InView>
                    );
                  }
                )}
                mode={SLIDER_MODE}
                navigation={isLaptopSize ? "ARROWS" : "DOTS"}
                slidesConfig={slidesConfig}
              />
            </div>
          </SkeletonWrapper>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export const ProductRecommendations = withErrorBoundary(_Handler);
