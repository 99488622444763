import { NextImage } from "@/components/common/NextImage/NextImage";
import { ProductRecommendations } from "@/components/PersonalizedProductLists/Handler/Handler";
import {
  useUniqueProps,
  type HandlerProps,
} from "@/components/PersonalizedProductLists/Handler/Handler.helper";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import Breakpoints from "@/react-app/styles/breakpoints.config";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { getImagePriority } from "@/react-components/Banners/Shared/BannerContent/BannerContent.helper";
import { BannerVideo } from "@/react-components/CategoryContent/CategoryComponents/BannerVideo";
import { CaretIcon } from "@/react-components/Common/Icons/Caret";
import { DisclaimerInformation } from "@/react-components/DisclaimerInformation";
import { CategoryIcon } from "@/react-components/MegaMenu/Mobile/CategoryIcon";
import {
  LinkDisplayName,
  PersonalizedWrapper,
  Link as StyledLink,
} from "@/react-components/PersonalizedProductList/ProductList.styled";
import {
  BREAKPOINTS,
  useBreakpoint,
} from "@/react-hooks/useBreakpoint/useBreakpoint";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import {
  hasValue,
  isNotEmpty,
  isNotNull,
  isNotNullOrUndefined,
} from "@xxl/common-utils";
import type {
  ContentProductCarouselAllOfVisualMedia,
  Image,
  Link,
  Video,
  ContentProductCarouselAllOfDescriptionField,
} from "@xxl/content-api";
import { ArrowRight } from "@xxl/icons";
import { useEffect, useState } from "react";
import {
  CarouselWrapper,
  Description,
  DescriptionLinkIndicator,
  DescriptionLinkWrapper,
  DisclaimerInformationWrapper,
  Heading,
  HeadingTextWrapper,
  ImageWrapper,
  MediaWrapper,
  SliderWrapper,
  Wrapper,
} from "./ContentProductCarousel.styled";
import { XxlStack } from "@/react-components/Common/XxlStack";
import type { BaseProductData } from "@xxl/product-search-api";
import { toProductCardDataFromBase } from "@/react-utils/ProductData/product-card-data-helper";
import { useProductRecommendations } from "@/react-hooks/useProductRecommendations/useProductRecommendations";
import { log } from "@xxl/logging-utils";
import { LIMIT } from "react-app/src/hooks/useProductRecommendations/constants";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "@/components/Pdp/queries/queryKeys";
import { useStateValue } from "cotton-box-react";
import { useSessionSource } from "@/react-app/contexts/Session";
// eslint-disable-next-line no-restricted-imports
import { useInView } from "react-intersection-observer";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";
import { Text } from "@/react-components/Text";

const { spaces } = xxlTheme;

export type ContentProductCarouselProps = HandlerProps & {
  backgroundColor?: string;
  categoryCode?: string;
  descriptionField?: ContentProductCarouselAllOfDescriptionField;
  disclaimerInformation?: {
    buttonColor: string;
    description: string;
  };
  fontColor?: string;
  hasTopMargin?: boolean;
  imagePosition?: string;
  isHighPrioComponent?: boolean;
  linkText?: string;
  linkUrl?: string;
  productsCount?: number;
  subTitle?: string;
  title?: string;
  visualMedia?: ContentProductCarouselAllOfVisualMedia;
};

const DEFAULT_IMAGE_POSITION = "left";
const IMAGE_POSITION_RIGHT = "right";

const handleResponse = ({
  baseProducts,
}: {
  baseProducts: BaseProductData[];
}) =>
  baseProducts.map((baseProduct) => ({
    hasRewardsPrices: baseProduct.products.at(0)?.price.type === "REWARD",
    ...toProductCardDataFromBase(baseProduct),
  }));

const _ContentProductCarouselComponent = ({
  backgroundColor,
  categoryCode,
  descriptionField,
  disclaimerInformation,
  fontColor,
  hasTopMargin = true,
  isHighPrioComponent = false,
  linkText,
  linkUrl,
  productsCount = LIMIT,
  subTitle,
  title,
  visualMedia = {},
  ...props
}: ContentProductCarouselProps) => {
  const { strategy, styleId } = props;
  const breakpoint = useBreakpoint();
  const { t } = useTranslations();

  const hasImage =
    "image" in visualMedia && isNotNullOrUndefined(visualMedia.image);
  const imagePosition = props.imagePosition ?? DEFAULT_IMAGE_POSITION;
  const imageData = hasImage ? (visualMedia.image as Image) : undefined;
  const hasVideo =
    "video" in visualMedia && isNotNullOrUndefined(visualMedia.video);
  const videoData = hasVideo ? (visualMedia.video as Video) : undefined;
  const hasDescription = isNotNullOrUndefined(descriptionField?.description);
  const [mediaWidth, setMediaWidth] = useState("0");
  const [sliderWidth, setSliderWidth] = useState("100%");
  const [iconSize, setIconSize] = useState(36);
  const imageSizes = `(max-width: ${Breakpoints.tablet}px) 100vw, (max-width: ${
    Breakpoints.laptop - 1
  }px) 33vw, (max-width: ${Breakpoints.desktop - 1}px) 25vw, 33vw`;
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const disclaimerInformationIconSize = isLaptop ? 24 : 18;
  const isLoggedIn = useStateValue(useSessionSource);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const uniqueProps = useUniqueProps(props);
  const { brands, campaigns, categories, includedProducts, productIdsInCart } =
    uniqueProps ?? {};

  const { ref: inViewRef } = useInView({
    triggerOnce: true,
    threshold: 0.01,
    onChange: (inView) => {
      setShouldFetchData(inView);
    },
    rootMargin: "300px 0px",
  });

  const {
    getAlternativesRecs,
    getCartPageRecommendations,
    getFrequentlyBoughtTogether,
    getPersonalRecs,
    getRecentlyViewed,
    getTopProductsRecs,
  } = useProductRecommendations();

  const fetchRecommendations = async () => {
    if (uniqueProps === null) {
      return;
    }

    try {
      switch (strategy) {
        case "frequently-bought-together":
          return handleResponse({
            baseProducts: await getFrequentlyBoughtTogether(styleId),
          });
        case "upsale":
          return handleResponse({
            baseProducts: await getAlternativesRecs(styleId),
          });
        case "recently":
          return handleResponse({
            baseProducts: await getRecentlyViewed(styleId),
          });
        case "bestseller":
        case "popularity":
          return handleResponse({
            baseProducts: await getTopProductsRecs({
              baseColors: props.baseColors,
              ...(isNotNullOrUndefined(brands) && {
                brandNames: brands,
              }),
              ...(isNotNullOrUndefined(campaigns) && {
                campaignIds: campaigns,
              }),
              ...(isNotNullOrUndefined(categories) && {
                categoryIds: categories,
              }),
              productsCount,
              ...(isNotNullOrUndefined(includedProducts) && {
                productKeys: includedProducts,
              }),
              users: props.users,
            }),
          });
        case "personalized":
          return handleResponse({
            baseProducts: await getPersonalRecs({
              baseColors: props.baseColors,
              ...(isNotNullOrUndefined(brands) && { brandNames: brands }),
              ...(isNotNullOrUndefined(campaigns) && {
                campaignIds: campaigns,
              }),
              ...(isNotNullOrUndefined(categories) && {
                categoryIds: categories,
              }),
              productsCount,
              ...(isNotNullOrUndefined(includedProducts) && {
                productKeys: includedProducts,
              }),
              users: props.users,
            }),
          });
        case "cart":
          return handleResponse({
            baseProducts: await getCartPageRecommendations(
              productIdsInCart ?? []
            ),
          });
        default:
          log.error("No such strategy: ", strategy);
      }
    } catch (error) {
      log.error("Could not fetch recommendations.");
      log.debug("Could not fetch recommendations.", error);
      return [];
    }

    return [];
  };

  const { data: carouselProducts, error } = useQuery({
    queryKey: [
      QUERY_KEYS.RECOMMENDATION,
      { strategy, brands, campaigns, categories, includedProducts, isLoggedIn },
    ],
    queryFn: fetchRecommendations,
    enabled: shouldFetchData,
  });

  if (error !== null) {
    log.debug("Recommendation could not be fetched.", {
      strategy,
      brands,
      campaigns,
      categories,
      includedProducts,
      isLoggedIn,
    });
  }

  useEffect(() => {
    if (isNotNullOrUndefined(videoData)) {
      if (
        breakpoint === BREAKPOINTS.mobile ||
        breakpoint === BREAKPOINTS.tablet
      ) {
        setMediaWidth(`calc(100dvw - 2 * ${spaces.smallRegular})`);
        setSliderWidth("100%");
      } else if (breakpoint === BREAKPOINTS.laptop) {
        setMediaWidth("546px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 546px)`);
      } else if (breakpoint === BREAKPOINTS.desktop) {
        setMediaWidth("666px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 666px)`);
      } else {
        setSliderWidth("666px");
      }
    }
    if (isNotNullOrUndefined(imageData)) {
      if (breakpoint === BREAKPOINTS.mobile) {
        setMediaWidth(`calc(100dvw - 2 * ${spaces.smallRegular})`);
        setSliderWidth("100%");
      } else if (breakpoint === BREAKPOINTS.tablet) {
        setMediaWidth("280px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.smallRegular} - 280px)`);
      } else if (breakpoint === BREAKPOINTS.laptop) {
        setMediaWidth("320px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 320px)`);
      } else if (breakpoint === BREAKPOINTS.desktop) {
        setMediaWidth("440px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 440px)`);
      } else {
        setMediaWidth("440px");
        setSliderWidth("892px");
      }
    }
    if (isNotNullOrUndefined(title)) {
      setIconSize(breakpoint === BREAKPOINTS.mobile ? 36 : 48);
    }
  }, [breakpoint, imageData, title, videoData]);

  const descriptionButtons = isNotNullOrUndefined(descriptionField?.buttons)
    ? (descriptionField.buttons as Link[])
    : null;

  const descriptionButton = isNotNull(descriptionButtons)
    ? descriptionButtons[0]
    : null;

  const Media = () => (
    <MediaWrapper
      componentWidth={mediaWidth}
      paddingLeft={imagePosition !== DEFAULT_IMAGE_POSITION}
      paddingRight={imagePosition === DEFAULT_IMAGE_POSITION}
      hasVideo={hasVideo}
      data-testid="content-product-carousel-media"
    >
      {hasImage && isNotNullOrUndefined(imageData) && (
        <>
          {hasValue(disclaimerInformation) && (
            <DisclaimerInformationWrapper>
              <DisclaimerInformation
                buttonText={t("general.close")}
                title={t("general.information")}
                iconSize={disclaimerInformationIconSize}
                color={disclaimerInformation.buttonColor}
              >
                {disclaimerInformation.description}
              </DisclaimerInformation>
            </DisclaimerInformationWrapper>
          )}
          <DescriptionLinkWrapper href={descriptionButton?.url ?? linkUrl}>
            {isNotEmpty(imageData.url) && (
              <ImageWrapper hasDescription={hasDescription}>
                <NextImage
                  src={imageData.url}
                  width={416}
                  height={235}
                  sizes={imageSizes}
                  alt={imageData.alt ?? ""}
                  {...getImagePriority(isHighPrioComponent)}
                />
              </ImageWrapper>
            )}

            {hasDescription && (
              <Description>{descriptionField.description}</Description>
            )}
            {isNotNull(descriptionButton) && (
              <DescriptionLinkIndicator>
                {descriptionButton.displayName}
                <ArrowRight />
              </DescriptionLinkIndicator>
            )}
          </DescriptionLinkWrapper>
        </>
      )}
      {hasVideo && isNotNullOrUndefined(videoData) && (
        <BannerVideo
          origin={videoData.origin}
          url={videoData.url}
          isContentProductCarousel={true}
        />
      )}
    </MediaWrapper>
  );

  if (carouselProducts?.length === 0) {
    return null;
  }

  return (
    <PersonalizedWrapper
      backgroundColor={backgroundColor}
      carouselType={strategy}
      data-testid={`product-recommendations-${strategy}`}
      fontColor={fontColor}
      hasTopMargin={hasTopMargin}
      isNextApp={true}
      ref={inViewRef}
    >
      <Wrapper>
        <Heading>
          {hasValue(categoryCode) && (
            <CategoryIcon
              code={categoryCode}
              size={iconSize}
              showFallback={true}
            />
          )}
          {hasValue(title) ? (
            <XxlStack flexGrow={1} gap={isLaptop ? spaces.mini : spaces.micro}>
              <HeadingTextWrapper>{title}</HeadingTextWrapper>
              {hasValue(subTitle) ? (
                <p style={{ margin: 0 }}>{subTitle}</p>
              ) : null}
            </XxlStack>
          ) : null}
          {hasValue(linkText) && hasValue(linkUrl) && (
            <StyledLink
              backgroundColor={backgroundColor}
              fontColor={fontColor}
              href={linkUrl}
              carouselType={strategy}
            >
              <LinkDisplayName>{linkText}</LinkDisplayName>
              <Text size="base" style={{ display: "flex", height: "1lh" }}>
                <CaretIcon direction="right" />
              </Text>
            </StyledLink>
          )}
        </Heading>
        <CarouselWrapper hasVideo={hasVideo}>
          {(isNotNullOrUndefined(imageData) ||
            isNotNullOrUndefined(videoData)) &&
            imagePosition === DEFAULT_IMAGE_POSITION && <Media />}
          <SliderWrapper
            componentWidth={sliderWidth}
            hasImage={hasImage}
            hasVideo={hasVideo}
            imagePosition={String(imagePosition)}
          >
            <ProductRecommendations
              carouselProducts={carouselProducts}
              sliderConfig={{
                slidesPerView: {
                  laptop: hasImage || hasVideo ? 4 : 6,
                  mobile: 2,
                  tablet: 3,
                },
                spacing: 6,
              }}
              strategy={strategy}
            />
          </SliderWrapper>
          {(isNotNullOrUndefined(imageData) ||
            isNotNullOrUndefined(videoData)) &&
            imagePosition === IMAGE_POSITION_RIGHT && <Media />}
        </CarouselWrapper>
      </Wrapper>
    </PersonalizedWrapper>
  );
};

export const ContentProductCarouselComponent = withErrorBoundary(
  _ContentProductCarouselComponent
);
